.alert-header {
    display: flex;
    flex-grow: 1;
    padding: 16px;
    margin-bottom: 16px;
}

.alert-title {
    flex: 1;
}

.alert-body {
    padding: 0px;
}

.alert-actions {
    display: inline;
}
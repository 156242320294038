.spreadsheet-side-content {
    background: #ffffff !important;
    border: 1px solid #e6e6e6;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 7px;
    border-radius: 15px;
    padding: 0  16px 22px 16px !important;
    &__title {
        font-weight: 500;
        font-size: 20px;
        color: #009688;
        margin: 12px 0 22px 0;
    }
    &__container {
        background: #ffffff !important;
        width: 100% !important;
        padding: 0 !important;
    }
    &__chip-content {
        display: flex;
        align-items: center;
    }
    &__column {
        display: grid;
        grid-template-columns: max-content auto 13px;
        background-color: #009688 !important;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
        border-radius: 57px;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        color: white;
        padding: 5px 12px 5px 16px !important;
        text-align: left;
        &__mandatory {
            background-color: rgba(0, 150, 136, 0.7) !important;
        }
        &__index {
            border-right: 1px solid #ffffff;
            padding-right: 13px;
        }
        &__title {
            margin-left: 13px;
            text-transform: capitalize;
            padding: 3px 10px 3px 3px;
        }
        &__button {
            display: flex;
            &__icon {
                background-color: white;
                font-size: 13px !important;
                color: #009688;
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }
}

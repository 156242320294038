.select-period {
    display: flex;
    align-items: center;
    .disable-button-css {
        border: none;
        cursor: pointer;
        padding: 0 0;
    }
    &__select {
        min-width: 151px;
        position: relative;
        &__value {
            padding: 9px 0 !important;
            color: #7d888d;
            font-size: 12px;
            background-color: white;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            width: 100%;
        }
        &__options-container {
            margin-top: 7px;
            background: #ffffff;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            padding: 3px !important;
            position: absolute;
            width: 100%;

            &__option {
                display: block;
                margin: 3px 0;
                padding: 3px 0 !important;
                width: 100%;
                background: white;
                border-radius: 21px;
                &:hover {
                    background: #3d91ff;
                    color: white;
                }
                &__active {
                    background: #3d91ff;
                    color: white;
                }
            }
        }
    }
}

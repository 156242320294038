.inputWithButton {
    position: relative;
    width : 100%;
  }
  
  .inputWithButton input{
      width: 70%;
      height: 25px;
      padding-right: 60px;
  }
  
  .inputWithButton button {
    position: absolute;
    right: 0;
    top : 14px;
  }
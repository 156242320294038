.downloads-filename-cell {
    display: flex;
    align-items: center;
    .icon-file {
        margin-right: 8px;
        max-width: 35px;
    }
}

.section-download-wrap {
    min-height: 100%;
}
.period-panel {
    &__select-container {
        margin: auto;
    }
    &__footer {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #009688;
        margin: 20px 10px;
        cursor: pointer;
    }
    &__table-container {
        min-width: 332px;
        text-align: center;
        max-height: 350px;
        overflow: auto;

        table {
            border-collapse: collapse;
            width: 100%;
        }
        th,
        tr {
            padding: 10px;
            cursor: pointer;
        }
        td {
            padding: 8px 0 0 0;
            font-size: 12px;
            &:first-child {
                div {
                    border-top-left-radius: 21px;
                    border-bottom-left-radius: 21px;
                    margin-left: 6px;
                }
            }
            &:last-child {
                div {
                    border-top-right-radius: 21px;
                    border-bottom-right-radius: 21px;
                    margin-right: 6px;
                }
            }
            div {
                background-color: white;
                padding: 3px 0;
            }
        }
        &__active-row {
            td {
                div {
                    background-color: #3d91ff;
                    color: white;
                    padding: 3px 0;
                }
            }
        }
        &__disabled-row {
            td {
                pointer-events: none;
                color: #b6b6b6;
            }
        }
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
    font-family: Roboto, Lato, sans-serif !important;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: none;
}

h3,
h4,
h5,
h6 {
    font-weight: normal;
    color: rgb(83, 83, 83);
}

.full-height {
    height: 100%;
}

.unset-button-css {
    background-color: transparent;
    border: none;
}

.cursor-pointer {
    cursor: pointer;
}

.sub-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    float: left;
    padding-bottom: 120px;
    padding-right: 1%;
    min-width: 990px;
    .pim-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .pim-title-page {
            color: #959A9E;
        }
    }
    .pim-count-results {
        font-size: 30px;
        color: #7d55ab;
        margin: 13px 0;
    }
}

.loader-container {
    position: relative;
    float: left;
    width: 100%;
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px dashed #ccc;
    text-align: center;
    padding: initial;
}

.style-red {
    color: #F44336 !important;
    font-weight: bold;
}

.style-green {
    color: #64DD17 !important;
    font-weight: bold;
}

.style-orange {
    color: #FF9800 !important;
    font-weight: bold;
}

.style-chip-red {
    background-color: #F44336 !important;
    span {
        color: #FFF !important;
    }
}

.style-chip-orange {
    background-color: #FF9800 !important;
    span {
        color: #FFF !important;
    }
}

.style-chip-green {
    background-color: #64DD17 !important;
    span {
        color: #FFF !important;
    }
}

/*paper styles*/

.paper-style {
    float: left;
    position: relative;
    margin-bottom: 1%;
    min-height: 260px;
    border: 1px solid rgb(232, 232, 232);
}

.paper-1 {
    min-width: 190px;
    height: 268px;
    margin-right: 1%;
}

.paper-2 {
    min-width: 390px;
    margin-right: 1%;
}

.paper-n {
    min-width: 340px;
}

@media (max-width: 1024px) {
    .paper-1 {
        width: 49%;
    }
    .paper-2 {
        width: 99%;
    }
    .paper-n {
        width: 99%;
    }
}

@media (min-width: 1024px) {
    .paper-1 {
        width: 24%;
    }
    .paper-2 {
        width: 49%;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .paper-n {
        width: 49%;
    }
}

@media (min-width: 1280px) and (max-width: 1920px) {
    .paper-n {
        width: 32.333333%;
    }
}

@media (min-width: 1920px) {
    .paper-n {
        width: 24%;
    }
}

.paper-full {
    width: 100%;
}

.paper-style.hero {
    height: auto;
    padding: 20px 0;
    min-height: 547px;
}

.paper-style.table>div {
    overflow: hidden !important;
}

.paper-style.padding {
    padding: 20px;
}

.paper-style>h3 {
    text-align: center;
    padding: 0 20px 20px 20px;
    margin: 0;
}

.paper-style>.content {
    position: relative;
    text-align: center;
    padding: 30px;
    font-size: 36px;
}

.paper-style>.content span {
    font-size: 14px;
    color: rgb(170, 170, 170);
}

.paper-style .content .last-content {
    position: absolute;
    top: 0;
    left: 50%;
    font-size: 14px;
    font-weight: bold;
    width: 50%;
    padding-left: 30px;
    text-align: left;
}

.paper-style .content .last-content .arrow {
    user-select: none;
    display: inline-block;
    fill: currentcolor;
    height: 40px;
    width: 40px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: -5px;
    left: 0;
}

.paper-style .mm-content {
    position: relative;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    font-size: 13px;
}

.paper-all-width {
    width: 100%;
    margin-top: 1%;
    position: relative;
    min-height: 260px;
    border: 1px solid rgb(232, 232, 232);
}

/*highcharts styles*/

.highcharts-credits {
    display: none;
}

.highcharts-container {
    width: 100% !important;
}

.highcharts-title tspan,
.highcharts-title title,
.highcharts-label tspan {
    color: rgb(83, 83, 83) !important;
    fill: rgb(83, 83, 83) !important;
}

.highcharts-background {
    fill: transparent !important;
}

// .paper-style.gauge {
//     padding: 10px;
// }

// .paper-style.gauge>div {
//     height: 180px;
//     width: 100%;
//     overflow: visible!important;
// }

// .paper-style.gauge>div>div {
//     height: 200px !important;
//     overflow: initial !important;
// }

// .paper-style.gauge>div>div>svg {
//     overflow: initial !important;
// }

// .paper-style.gauge>div>div>svg>defs>clipPath>rect {
//     height: 200px !important;
// }

.paper-style .custom-bar-style {
    opacity: 0.5;
}

.custom-chip {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    display: block;
    position: relative;
    color: rgb(83, 83, 83);
    margin-left: 0px;
    padding: 20px 0px 20px 42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    img,
    div {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        border: 1px solid #eee;
        background-color: #fff !important;
    }
}

.chip-size {
    img,
    div {
        height: 32px;
        width: 32px;
    }
}

.chip-mini-size {
    img,
    div {
        height: 26px;
        width: 26px;
    }
}

.chip-inline-style {
    display: inline-flex !important;
    font-size: 11px;
    margin-right: 4px;
    margin-left: 1px;
    padding: 10px 0 10px 26px;
}

.custom-caption {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    margin: 0 auto;
    color: #aaaaaa;
    font-size: 12px;
    font-weight: normal;
}

.extrafilter-modal-style div div h3 {
    margin: 0px 0px 20px 0px !important;
}

.link-style {
    text-decoration: none;
    color: inherit;
    display: block;
    &.icon {
        display: contents;
    }
}

.custom-sub-table>div>div:nth-child(n+2)>table tr {
    height: 56px !important;
}

.tree-list-enter {
    animation-name: tree-list-enter;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transform: translateZ(0);
}

.tree-list-leave {
    animation-name: tree-list-leave;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

@keyframes tree-list-enter {
    0% {
        height: 0px;
        opacity: 0.01;
    }
    100% {
        height: 32px;
        opacity: 1;
    }
}

@keyframes tree-list-leave {
    0% {
        height: 32px;
        opacity: 1;
    }
    100% {
        height: 0px;
        opacity: 0.01;
    }
}

.accordion-style>div>div:first-child {
    border-left: 6px solid #f44336;
}

.full-width,
.full-width>div {
    overflow: initial !important;
    white-space: initial !important;
    text-overflow: initial !important;
}

@media (max-width: 1378px) {
    .custom-dropdown div div span {
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 1378px) {
    .custom-dropdown div div span {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 850px) {
    .paper-style.search {
        width: 99% !important;
    }
}

@media (max-width: 1178px) and (min-width: 800px) {
    .paper-style.search {
        width: 48.5% !important;
    }
}

@media (max-width: 1460px) and (min-width: 1178px) {
    .paper-style.search {
        width: 32.1% !important;
    }
}

@media (min-width: 1460px) {
    .paper-style.search {
        width: 23.75% !important;
    }
}

.app-bar-custom {
    background-color: #FFF !important;
    box-shadow: none !important;
}

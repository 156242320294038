.wrap-nodata {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;

    &__circle {
        justify-self: flex-end;
        width: 300px;
        height: 100%;
        max-height: 300px;
        border: 8px solid #009688;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &__text {
            color: black;
            font-weight: bold;
            font-size: 24px;
            text-align: center;
        }
    }
    h4 {
        font-weight: bold;
        padding: 10px
    }
    ul, li{
        list-style: none;
        color: #009688;
    }
}

.universal-chart {
    &__price-range {
        display: flex;
        position: relative;
        &__input {
            width: 225px;
            margin-left: 10px !important;
            input {
                padding-right: 110px !important;
            }
        }
        &__button {
            min-width: unset !important;
            position: absolute !important;
            bottom: 8px;
            right: 0px;
            font-size: 13px !important;
            padding: 3px 0 !important;
            width: 105px !important;
        }
    }
}

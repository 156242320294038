@import '~normalize.css/normalize';
// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want

*,
*:before,
*:after {
    box-sizing: border-box;
    font-family: Roboto, Lato, sans-serif !important;
}


body {
    margin: 0;
    padding: 0;
    height: 100%;
}

body{
    background-color: #eee;
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: none;
}

h3,
h4,
h5,
h6 {
    font-weight: normal;
    color: rgb(83, 83, 83);
}

.datagram-img{
    height: 42px;
    margin-right: 15px;
    margin-left: 15px;
}
  

.sub-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    float: left;
    padding-bottom: 120px;
    padding-right: 1%;
    // padding-left: 1%;
    min-width: 990px;
    .pim-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .pim-title-page {
            color: #959A9E;
        }
    }
    .pim-count-results {
        font-size: 30px;
        color: #7d55ab;
        margin: 13px 0;
    }
}

.loader-skeleton{
    position: relative;
    width: 99%;
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 1%;
    margin-left: 1%;
    text-align: center;
    padding: initial;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.style-red {
    color: #F44336 !important;
    font-weight: bold;
}

.style-green {
    color: #64DD17 !important;
    font-weight: bold;
}

.style-orange {
    color: #FF9800 !important;
    font-weight: bold;
}

.style-chip-red {
    background-color: #F44336 !important;
    span {
        color: #FFF !important;
    }
}

.style-chip-orange {
    background-color: #FF9800 !important;
    span {
        color: #FFF !important;
    }
}

.style-chip-green {
    background-color: #64DD17 !important;
    span {
        color: #FFF !important;
    }
}

.custom-chip {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    display: block;
    position: relative;
    color: rgb(83, 83, 83);
    margin-left: 0px;
    padding: 20px 0px 20px 42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    img,
    div {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        border: 1px solid #eee;
        background-color: #fff !important;
    }
}

.chip-size {
    img,
    div {
        height: 32px!important;
        width: 32px!important;
        margin-left: 0!important;
    }
}

.chip-mini-size {
    img,
    div {
        height: 26px;
        width: 26px;
    }
}

.chip-inline-style {
    display: inline-flex !important;
    font-size: 11px;
    margin-right: 4px;
    margin-left: 1px;
    padding: 10px 0 10px 26px;
}

.custom-caption {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    margin: 0 auto;
    color: #aaaaaa;
    font-size: 12px;
    font-weight: normal;
}

.extrafilter-modal-style div div h3 {
    margin: 0px 0px 20px 0px !important;
}

.row-detail-help{
    font-size: 18px!important;
    color: #ccc!important 
}
.row-custom-detail-head{
    width: 14%;
    height: 32px;
    position: relative;
    float: left;
    text-align: center;
}

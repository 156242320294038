@tailwind base;

/* https://github.com/niklasvh/html2canvas/issues/2775#issuecomment-1897584599 */

@layer base {
    img {
        @apply inline-block;
    }
}

@tailwind components;
@tailwind utilities;
.dg-style {
    max-width: 100%;

    .MuiChip-label {
        >div {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .MuiChip-avatar {
        width: 32px !important;
        margin-left: 0px !important;
        height: 32px !important;
        background-color: #FFFFFF;
    }
}

.table-grid-component {
    table {
        thead {
            th {
                padding: 0 !important;
                height: 50px;

                & button {
                    padding: 0px 0px !important;
                }

                &:first-child {
                    padding-left: 20px !important;
                }

                //position: sticky;
                //top: 0; /* Don't forget this, required for the stickiness */
                //z-index: 1000;
                //background-color: white;
            }
        }

        tbody {
            .evo-cell {
                padding-right: 10px !important;
                height: 50px;
                vertical-align: middle;

                &:first-child {
                    padding-left: 10px !important;
                }
            }

            tr {
                min-height: 50px;

                td {
                    padding: 0 !important;
                    overflow: unset;
                }
            }
        }
    }
}

.MuiTable-root,
.MuiTableCell-root {
    thead {
        &.MuiTableHead-root {
            >tr:nth-child(2) {
                display: none;
            }

            position: sticky;
            top: 0;
            //z-index: 1000;
            background-color: white;
        }

        tr {
            th {
                span {
                    font-weight: bold;
                }
            }
        }

        button {
            font-weight: bold;
        }
    }

    .MuiTableBody-root {

        tr {
            td {
                a {
                    .MuiChip-root {
                        cursor: pointer !important;
                        width: 100%;

                        &:hover {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}


.product-cell span {
    max-height: 34px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.count-cell span {
    color: rgb(170, 170, 170);
}

.evo-cell {

    &__images-count {
        font-size: 16px;
        margin-right: 5px;
    }

    &__image-link-cell-button {
        display: flex;
        align-items: center;
    }

    &__long-text {
        width: inherit;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .sub {
        display: flex;
        align-items: center;
        width: 100%;
        top: -3px;

        &.row {
            top: 23px;
        }

        span {
            float: left;
            font-size: 11px;
            line-height: 11px;
        }
    }
}

.grid-checkbox span {
    justify-content: initial;
}


.row-detail-container {
    display: flex;
    flex-direction: column;
    background-color: white;

    &__empty-row-details {
        tbody {
            tr {
                td:first-child {
                    button {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

.row-detail-header {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    width: 97%;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.row-detail-title {
    width: 12.5%;
    text-align: left;
    padding: 0 10px !important;
}

.row-detail-content {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 5px 0;
    width: 97%;
    height: 49px;
    background-color: #fafafa;

    td {
        width: 12.5%;
        text-align: left;
        border-bottom: none;

        a {
            .MuiChip-root {
                width: auto !important;
            }
        }

    }

    &:last-child {
        border-bottom: none;
    }
}

.cell-chip-conteiner {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.image-modal {
    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        width: min-content;
        padding: 4;
        display: flex;
        justify-content: center;

        &__body {
            background-color: white;
            min-width: 500px;

            &__title {
                align-items: center;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid rgb(196, 196, 196);

                >div:first-child {
                    display: flex;
                    width: 100%;

                    div {
                        font-weight: bold;
                    }

                    center {
                        width: 100%;
                    }
                }

                >div:nth-child(2) {
                    cursor: pointer;
                }
            }

            &__content {
                padding: 10px;
                display: flex;
                justify-content: space-around;
                align-items: center;
            }
        }
    }
}

div[class^="TableContainer-root-"] {
    width: 100%;
    overflow: auto;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;

    position: sticky;
    height: 100vh;
    overflow: auto;
    scrollbar-width: none
}

div[class^="TableContainer-root-"]::-webkit-scrollbar {
    display: none;
}
.core-layout__viewport {
  padding-left: 70px;
  height: 100%;
  width: 100%;
  background-color: #eee;
  overflow: auto;
  position: relative;
}

.container {
  width: 100%;
  padding: 0;

  &.lightbar {
    padding-top: 140px !important;
  }

  &.ean-style {
    padding-top: 117px !important;
  }

  &.search-style {
    padding-top: 95px !important;
  }

  &.pim-style {
    padding-top: 80px !important;
    padding-left: 20px !important;
  }
}

.container.ean-style.lightbar {
  padding-top: 152px !important;
}

// td, th {
//     padding-left: 5px !important;
//     padding-right: 5px !important;
// }

td.custom-sub-table {
  padding-left: 0px !important;
  padding-right: 0px !important;

  &.padding {
    padding-bottom: 60px !important;
  }

  &.last-line tr:last-child td {
    border-bottom: none;
  }
}

td.padding-td {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.search-input-table {
  width: 160px !important;
  padding: 5px !important;
  background-color: rgba(0, 0, 0, .07) !important;
  border-radius: 4px;
  margin-right: 10px !important;

  hr {
    display: none;
  }

  line-height: 13px !important;

  input {
    font-size: 15px !important;
  }

  div::before {
    border: none;
  }

  &.custom-position {
    right: 100px;
  }
}

.search-input-wrapper {
  height: 52px;
  align-items: center;
  width: 100%;
  padding: 10px;
  background: #FFF;
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 10px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}


.btn-search {
  box-shadow: initial !important;
  border: 1px solid rgba(224, 224, 224, 1) !important;
  color: rgba(0, 0, 0, 0.5) !important;
  margin-right: 10px !important;

  &.custom-position {
    right: 10px !important;
  }
}



.title-table {
  padding: 15px;
  margin: 0;
  font-size: 16px;
  width: 100%;
  text-align: center;
}

.grey900 {
  background-color: #212121 !important;

  span {
    color: #fff !important;
  }
}

.green300 {
  background-color: #66bb6a !important;
}

.red300 {
  background-color: #ef5350 !important;
}

.orange300 {
  background-color: #ffa726 !important;
}

.split-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}

.export-button-wrap {
  display: flex;
  flex-wrap: nowrap;
}

.button-left {
  justify-content: flex-start;
}

.custom-hcell>div {
  width: max-content;
  margin: 0 auto;
}

.data-modal-cell {
  padding: 2px;
  position: relative;

  &:hover {
    border: 1px dashed #009688;
    border-radius: 4px;
    cursor: pointer;

    svg {
      display: block;
    }
  }

  svg {
    display: none;
    color: #009688;
    position: absolute;
    top: -2px;
    right: -18px;
  }
}

#simple-menu {
  z-index: 9999999;
  top: 112px !important;
}

#menu-multile-select {
  z-index: 9999999;
}

.chart-wrap {
  position: relative;
  border-top: 1px solid #e8e8e8;

  &__title,
  &__input {
    margin: 0;
    border-bottom: 1px solid #e8e8e8;
  }

  &__title {
    padding: 17px;
  }

  &__input {
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
  }

  &__left_input {
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
  }

  &__form {
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .chart-title {
    font-size: 16px;
    margin: 0;
    text-align: center;
    width: 100%;
    font-weight: bold;
  }
}
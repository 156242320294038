.weekly-update-unsubscription {
    position: absolute;
    width: 400px;
    padding: 20px;
    text-align: center;
    left: 50%;
    top: 125px;
    transform: translate(-50%, 0);
    background-color: #EEE;
    > div {
        position: relative;
    }
}
  
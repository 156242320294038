.search-bloc {
    width: 100%;
    position: relative;
    float: left;
    height: 100%;
    > a {
        height: 100%;
        width: 100%;
        position: relative;
        float: left;
        > div > img {
            max-height: 100%;
            max-width: 100%;
            margin: 0 auto;
            position: absolute;
            right: 0;
            left: 0;
        }
    }
}

.sub-search-bloc {
    width: 100%;
    position: relative;
    float: left;
    font-size: 13px;
    margin-top: 10px;
}

.top-indent {
    margin-top: 10px;
}
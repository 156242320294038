.card-chart {
  position: relative;
  div:first-child {
    overflow: visible !important;
    div:first-child {
      overflow: visible !important;
      svg:first-child {
        overflow: visible !important;
      }
    }
  }
}
.highcharts-point {
  font-family: "Font Awesome 5 Free";
}
.apexcharts-tooltip {
   padding: 2.5px;
}

.gauge-chart {
    position: relative;
    div:first-child {
        overflow: visible !important;  
        div:first-child {
            overflow: visible !important; 
            svg:first-child {
                overflow: visible !important; 
            }
        }  
    }

}
.loading-wrapper-container {
    width: 100%;
    background-color: white;
    min-height: unset;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__header {
        height: 52px;
        display: flex;
        justify-content: center;
        padding: 10px;
        border-bottom: 1px solid #e8e8e8;
    }
    &__footer {
        padding: 10px;
        border-top: 1px solid #e8e8e8;
    }
    &__universal-chart {
        padding: 32px;
        height: 100%;
        &__bars {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }
    &__area-pie {
        display: flex;
        justify-content: space-around;
        padding: 32px;
    }
    &__table {
        padding: 18px;
        height: 100%;
        overflow: auto;
        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

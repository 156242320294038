.modify__wrapper {
  display: flex;
  flex-direction: row;
}
.modify__item {
  display: flex;
  flex-direction: column;
}
.modify__item:first-child {
  margin-right: 15px;
}
.modify__item p {
  margin: 0;
  font-weight: 600;
  font-size: 10px;
}
.modify__input {
  font-size: 15px;
  width: 140px;
  border: 2px solid #e9e9e9 !important;
  border-radius: 4px;
  margin-top: 10px !important;
  padding: 5px !important;
}
